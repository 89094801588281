import { F, O } from "@mobily/ts-belt";
import { normalText, type BoldableText, boldText } from "../BoldableText";
import { match } from "ts-pattern";
import Section3Image1 from "/public/assets/mobile/images/pic/img_main_section3_1.webp";
import Section3Image2 from "/public/assets/mobile/images/pic/img_main_section3_2.webp";
import Section3Image3 from "/public/assets/mobile/images/pic/img_main_section3_3.svg";
import Section3Image4 from "/public/assets/mobile/images/pic/img_main_section3_4.webp";
import Section3Image5 from "/public/assets/mobile/images/pic/img_main_section3_5.webp";
import Section3Image6 from "/public/assets/mobile/images/pic/img_main_section3_6.webp";
import Section3Image7 from "/public/assets/mobile/images/pic/img_main_section3_7.webp";
import Section3Image8 from "/public/assets/mobile/images/pic/img_main_section3_8.webp";
import Section3Image9 from "/public/assets/mobile/images/pic/img_main_section3_9.webp";
import Section3Image10 from "/public/assets/mobile/images/pic/img_main_section3_10.svg";
import Section3Image11 from "/public/assets/mobile/images/pic/img_main_section3_11.svg";
import type { StaticImageData } from "next/image";

export const 마켓봄피쳐_기본: 마켓봄피쳐[] = [
  "거래처주문앱",
  "자동취합",
  "미수금0",
  "무제한추가",
  "맞춤상품",
  "거래처별원클릭단가",
  "유통사앱즉시확인",
  "언제어디서나",
  "원격세팅지원",
  "하나로관리",
  "인쇄커스텀제공",
];

export type 마켓봄피쳐 =
  | "거래처주문앱"
  | "자동취합"
  | "미수금0"
  | "무제한추가"
  | "맞춤상품"
  | "거래처별원클릭단가"
  | "유통사앱즉시확인"
  | "언제어디서나"
  | "원격세팅지원"
  | "하나로관리"
  | "인쇄커스텀제공";

export const make = (value: string) => {
  return match(value)
    .returnType<O.Option<마켓봄피쳐>>()
    .with("거래처주문앱", () => "거래처주문앱")
    .with("자동취합", () => "자동취합")
    .with("미수금0", () => "미수금0")
    .with("무제한추가", () => "무제한추가")
    .with("맞춤상품", () => "맞춤상품")
    .with("거래처별원클릭단가", () => "거래처별원클릭단가")
    .with("유통사앱즉시확인", () => "유통사앱즉시확인")
    .with("언제어디서나", () => "언제어디서나")
    .with("원격세팅지원", () => "원격세팅지원")
    .with("하나로관리", () => "하나로관리")
    .with("인쇄커스텀제공", () => "인쇄커스텀제공")
    .otherwise(() => O.None);
};

export type 섹션기본정보 = {
  제목: {
    단점: BoldableText[];
    장점: BoldableText[];
  };
  설명: BoldableText[][];
  이미지: StaticImageData;
};

const n = normalText;
const b = boldText;

export const 마켓봄피쳐_목록: () => Record<마켓봄피쳐, 섹션기본정보> = F.once(
  () => ({
    거래처주문앱: {
      제목: {
        단점: [n("카톡,문자,전화로 "), b("불편한 주문")],
        장점: [n("거래처(식당) 주문 앱으로 "), b("간단한 주문")],
      },
      설명: [
        [b("거래처 주문용 모바일 앱 지원"), n("으로")],
        [n("식당, 가맹점에서 간편하게 주문이 가능합니다.")],
      ],
      이미지: Section3Image1,
    },
    자동취합: {
      제목: {
        단점: [n("반복되는 장시간의 "), b("수발주 취합")],
        장점: [n("마켓봄은 "), b("주문 즉시 자동 취합")],
      },
      설명: [
        [n("매일 반복되는 장시간의 수발주 처리를")],
        [b("간편한 주문 시스템"), n("으로 즉시 자동 취합합니다.")],
      ],
      이미지: Section3Image2,
    },
    미수금0: {
      제목: {
        단점: [n("외상 거래로 "), b("미수금 증가")],
        장점: [n("마켓봄은 "), b("미수금 ZERO!")],
      },
      설명: [
        [b("다양한 선결제 방식"), n("(가상계좌,신용카드)과")],
        [b("미수금 관리 기능"), n("(한도 설정, 결제일 설정, 주문제한,")],
        [n("문자알림)으로"), b(" 외상거래를 감소"), n("시킬 수 있습니다.")],
      ],
      이미지: Section3Image3,
    },
    무제한추가: {
      제목: {
        단점: [n("거래처 추가 시 "), b("비싸지는 타사 요금제")],
        장점: [n("마켓봄은 거래처 "), b("무제한 무료")],
      },
      설명: [
        [n("마켓봄 월 최대 121,000원, 가입비 무료, 세팅비 무료")],
        [b("거래처 추가 비용 없이 무제한 생성"), n("이 가능합니다.")],
      ],
      이미지: Section3Image4,
    },
    맞춤상품: {
      제목: {
        단점: [n("비거래 "), b("상품 영업의 어려움")],
        장점: [n("마켓봄은 맞춤 상품 노출로 "), b("매출 증가")],
      },
      설명: [
        [n("비거래 신규 상품을 거래처의 주문 시스템에 노출,")],
        [n("신규 거래를 성사시켜 매출을 증가시킵니다.")],
        [n("(온라인 전단지, 쇼핑몰 운영 효과)"),],
      ],
      이미지: Section3Image5,
    },
    거래처별원클릭단가: {
      제목: {
        단점: [n("불편했던 "), b("거래처 별 단가관리")],
        장점: [n("마켓봄은 "), b("원클릭 관리")],
      },
      설명: [
        [b("단가그룹으로 여러개의 단가를 설정"), n("하여")],
        [n("식당, 가맹점 마다 각각 다른 가격에 판매가 가능합니다.")],
      ],
      이미지: Section3Image6,
    },
    유통사앱즉시확인: {
      제목: {
        단점: [n("실시간 확인이 어렵던 "), b("매출/매입 조회")],
        장점: [n("마켓봄은 "), b("유통사 앱으로 즉시 확인")],
      },
      설명: [
        [b("유통사 주문확인용 모바일 앱 제공"), n("으로")],
        [n("실시간으로 매출과 매입의")],
        [n("상세내역까지 확인할 수 있습니다.")],
      ],
      이미지: Section3Image7,
    },
    언제어디서나: {
      제목: {
        단점: [n("프로그램이 설치된 장소 외 "), b("업무 불가능")],
        장점: [n("마켓봄은 설치 없이 "), b("언제 어디서나 접속")],
      },
      설명: [
        [n("인터넷이 가능한 공간이면")],
        [b("언제 어디서든 업무"), n("가 가능합니다.")],
      ],
      이미지: Section3Image8,
    },
    원격세팅지원: {
      제목: {
        단점: [n("복잡하고 오래 걸리는 "), b("초기 세팅")],
        장점: [n("마켓봄은 "), b("긴급 방문과 원격 세팅 지원")],
      },
      설명: [
        [n("영업 사원이 방문부터 기존 전산 데이터를 이관,")],
        [b("원격 초기 세팅을 무료로 지원"), n("해드립니다.")],
      ],
      이미지: Section3Image9,
    },
    하나로관리: {
      제목: {
        단점: [n("유통사마다 "), b("프로그램 사용")],
        장점: [n("마켓봄은 "), b("하나로 관리!")],
      },
      설명: [
        [n("유통사 다중구조로 관리중인 유통사를 연결하여")],
        [b("하나의 프로그램으로 동시에 관리"), n("가 가능합니다.")],
      ],
      이미지: Section3Image10,
    },
    인쇄커스텀제공: {
      제목: {
        단점: [n("프로그램마다 "), b("다른 인쇄설정")],
        장점: [n("마켓봄은 "), b("직접 커스텀 제공!")],
      },
      설명: [
        [b("화면별 인쇄 설정"), n("으로 각 상황에 맞는")],
        [n("최적화된 인쇄 설정 가능하며, "), b("거래명세표 커스텀"), n("으로")],
        [n("기존 거래명세표와 동일하게 출력이 가능합니다.")],
      ],
      이미지: Section3Image11,
    },
  })
);
